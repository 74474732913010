import React, { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import PageTitle from 'components/common/PageTitle';
import { Tab, Nav } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { useTranslation } from 'react-i18next';
import { setInit, setError } from 'redux/slicers/appsettingSlice';
import axios from 'axios';
import endpoint from 'services/endpoint';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Card } from 'react-bootstrap';
import Treeview from 'components/common/tree-view/Treeview';
import Loading from 'components/loading';
import { getErrorMesssage } from 'services/axiosHelpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MyAccount from './MyAccount';
import EditUserModal from './modals/EditModal';
import DeleteUserModal from './modals/DeleteModal';
import DeleteContentModal from './modals/ContentDeleteModal';
import EditCategoryModal from './modals/CategoryEditModal';
import DeleteCategoryModal from './modals/CategoryDeleteModal';
import MyContent from '../content/MyContent';
import AddContentCategoryModal from '../modal/categories/AddContentCategoryModal';
import AddContentFormModal from '../modal/content/AddContentFormModal';
import Users from './users/Users';

const Admin = () => {
  const [formData, setFormData] = useState({
    category01ISbb_q4sg_content_categoriesID: '',
    category03ISbb_q4sg_content_categoriesID: ''
  });

  const _isMounted = useRef(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const appsetting = useSelector(state => state.appsetting);
  const category_tree = appsetting.category_tree;
  const { user_type } = appsetting.dropdowns;
  const userTypes = [...user_type];

  const user = useSelector(state => state.auth.user);
  const isAdmin = user && user.user_type && user.user_type === 'Admin';
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  const [kind, setKind] = useState('users');
  const [users, setUsers] = useState([]);
  const [openedItems, setOpenedItems] = useState([]);

  const title = isAdmin ? 'System Administration' : 'My account';

  const [selectedUser, setSelectedUser] = useState({});
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const handleDeleteUserModalClose = () => setShowDeleteUserModal(false);
  const handleEditUserModalClose = () => setShowEditUserModal(false);

  const [selectedCategory, setSelectedCategory] = useState({});
  const [showDeleteCategoryModal, setShowDeleteCategoryModal] = useState(false);
  const [showEditCategoryModal, setShowEditCategoryModal] = useState(false);
  const handleDeleteCategoryModalClose = () =>
    setShowDeleteCategoryModal(false);
  const handleEditCategoryModalClose = () => setShowEditCategoryModal(false);

  const [selectedContent, setSelectedContent] = useState({});
  const [showDeleteContentModal, setShowDeleteContentModal] = useState(false);

  const handleDeleteContentModalClose = () => setShowDeleteContentModal(false);

  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [showContentFormModal, setShowContentFormModal] = useState(false);
  const [contentFormModal, setContentFormModal] = useState();

  const handleOpenCategoryModal = () => setShowCategoryModal(true);
  const handleCloseCategoryModal = () => setShowCategoryModal(false);

  const handleOpenContentFormModal = () => setShowContentFormModal(true);
  const handleCloseContentFormModal = () => setShowContentFormModal(false);

  const kinds = [
    {
      key: 'my-account',
      name: t('MyAccount')
    },
    {
      key: 'my-content',
      name: t('MyContent')
    },
    {
      key: 'users',
      name: t('Users')
    }
  ];

  const onSelectUser = _id => {
    if (_id === null) {
      setSelectedUser({});
      return;
    }
    const user = users.find(u => u._id === _id);
    if (user) {
      setSelectedUser(user);
    }
  };

  const onAddUser = () => {
    onSelectUser(null);
    setShowEditUserModal(true);
  };

  const modifyCategoryStructure = categories => {
    let result = [];
    if (categories && categories.length > 0) {
      categories.forEach(category => {
        const cateChildren = modifyCategoryStructure(category.sub_branches);
        const fileChildren = category.content_list.map(f => {
          return {
            name: f.name,
            id: f._id,
            icon: 'file'
          };
        });
        const children = [...cateChildren, ...fileChildren];
        const newCate = {
          name: category.name,
          id: category._id,
          children
        };
        result = [...result, newCate];
      });
    }
    return result;
  };

  const modifyCategoryOnlyStructure = categories => {
    let result = [];
    if (categories && categories.length > 0) {
      categories.forEach(category => {
        const children = modifyCategoryOnlyStructure(category.sub_branches);
        const newCate = {
          name: category.name,
          id: category._id,
          children
        };
        result = [...result, newCate];
      });
    }
    return result;
  };

  const categories = useMemo(() => {
    let result = modifyCategoryStructure(category_tree);
    return result;
  }, [category_tree]);

  const categoriesOnly = useMemo(() => {
    let result = modifyCategoryOnlyStructure(category_tree);
    return result;
  }, [category_tree]);

  const handlerAppSettingData = async () => {
    _isMounted.current && setLoading(true);
    try {
      const response = await axios.get(endpoint.settings);
      console.log('-----app_settings----at Admin---------', response.data);
      const data = response.data;

      const background_color = data.background_color;
      const category_tree = data.category_tree;
      const default_route = data.default_route;
      const dropdowns = data.dropdowns;
      const env_files = data.env_files;
      const favicon_16ISfile = data.favicon_16ISfile;
      const favicon_32ISfile = data.favicon_32ISfile;
      const favicon_192ISfile = data.favicon_192ISfile;
      const languages = data.languages;
      const logoISfile = data.logoISfile;
      const name = data.name;
      const regions = data.regions;
      const secondary_color = data.secondary_color;
      const splash_logoISfile = data.splash_logoISfile;
      const title = data.title;
      const translations = data.translations;

      dispatch(
        setInit({
          background_color,
          category_tree,
          default_route,
          dropdowns,
          env_files,
          favicon_16ISfile,
          favicon_32ISfile,
          favicon_192ISfile,
          languages,
          logoISfile,
          name,
          regions,
          secondary_color,
          splash_logoISfile,
          title,
          translations
        })
      );
    } catch (err) {
      const message = getErrorMesssage(err);
      dispatch(
        setError({
          isShow: true,
          title: 'Error',
          message
        })
      );
    } finally {
      _isMounted.current && setLoading(false);
    }
  };

  const updateSuccess = () => {
    setup();
  };

  const updateCategorySuccess = async () => {
    console.log('------updateCategorySuccess---------');
    await handlerAppSettingData();
  };

  const onDeleteCategoryContent = item => {
    console.log('-------Delete------', item);
    const isCategory = item.children ? true : false;
    if (isCategory) {
      setSelectedCategory(item);
      setShowDeleteCategoryModal(true);
    } else {
      setSelectedContent(item);
      setShowDeleteContentModal(true);
    }
  };

  const getUsers = async userType => {
    try {
      _isMounted.current && setLoading(true);
      const ep = `${endpoint.getUsersEndpoint(userType)}`;
      const usersRes = await axios.get(ep);
      const usersData = usersRes.data;

      if (usersData.error) {
        const message = getErrorMesssage(usersData.error);
        dispatch(
          setError({
            isShow: true,
            title: 'Error',
            message
          })
        );
        return [];
      } else {
        return usersData;
      }
    } catch (err) {
      const message = getErrorMesssage(err);
      dispatch(
        setError({
          isShow: true,
          title: 'Error',
          message
        })
      );
      return [];
    } finally {
      _isMounted.current && setLoading(false);
    }
  };

  const getAllusers = async () => {
    let allUsers = [];
    for (let index = 0; index < userTypes.length; index++) {
      const user = userTypes[index];
      const tempUsers = await getUsers(user._id);
      allUsers = [...allUsers, ...tempUsers];
    }
    setUsers(allUsers);
  };

  const setup = async () => {
    setUsers([]);
    await getAllusers();
  };

  useEffect(() => {
    if (isAdmin && pathname === '/admin') {
      setup();
    } else if (!isAdmin && pathname === '/account') {
      setup();
    } else {
      navigate('/home');
    }
  }, []);

  return (
    <>
      <div>
        <PageTitle title={title} />
        {loading ? (
          <Card.Body className="">
            <Loading style={{ marginTop: 50 }} msg="Loading Data..." />
          </Card.Body>
        ) : (
          <div className="page-content mb-6">
            <Tab.Container
              activeKey={kind}
              onSelect={k => setKind(k)}
              id="countries-tab"
              transition={false}
            >
              <Flex justifyContent="between" alignItems="center" className="">
                <Nav variant="tabs" className="flex-row mb-3">
                  {kinds.map((c, index) => (
                    <Fragment key={index}>
                      <Nav.Item>
                        <Nav.Link
                          eventKey={c.key}
                          className="fw-semi-bold fs-1"
                        >
                          {t(c.name)}
                        </Nav.Link>
                      </Nav.Item>
                    </Fragment>
                  ))}
                </Nav>
                {kind === 'my-content' && (
                  <div className="button-wrapper">
                    <Button
                      color="primary"
                      className="w-100"
                      onClick={handleOpenCategoryModal}
                      disabled
                    >
                      <span>
                        <FontAwesomeIcon icon={'add'} className="me-2" />
                      </span>
                      <span>{t('Add Content')}</span>
                    </Button>
                    <div
                      style={{
                        fontWeight: 600,
                        paddingTop: 5,
                        fontSize: 22
                      }}
                    >
                      Under development
                    </div>
                  </div>
                )}
                {kind === 'users' && (
                  <div className="button-wrapper">
                    <Button
                      color="primary"
                      className="w-100"
                      onClick={onAddUser}
                    >
                      <span>
                        <FontAwesomeIcon icon={'add'} className="me-2" />
                      </span>
                      <span>{t('Add User')}</span>
                    </Button>
                  </div>
                )}
              </Flex>
              <Tab.Content>
                {kinds.map((c, index) => (
                  <Fragment key={index}>
                    <Tab.Pane eventKey={c.key} title={c.name} className="p-3">
                      {kind === 'my-account' && <MyAccount />}
                      {kind === 'my-content' && <MyContent />}
                      {kind === 'users' && (
                        <Users users={users} updateSuccess={updateSuccess} />
                      )}
                      {kind === 'categories' && (
                        <Treeview
                          data={categories}
                          setItem={onEditCategoryContent}
                          openedItems={openedItems}
                          setOpenedItems={setOpenedItems}
                          deleteItem={onDeleteCategoryContent}
                          addNewChildItem={addNewContentInCategory}
                        />
                      )}
                    </Tab.Pane>
                  </Fragment>
                ))}
              </Tab.Content>
            </Tab.Container>
          </div>
        )}
      </div>
      {showDeleteUserModal && (
        <DeleteUserModal
          show={showDeleteUserModal}
          _id={selectedUser._id}
          handleClose={handleDeleteUserModalClose}
          success={updateSuccess}
        />
      )}
      {showEditUserModal && (
        <EditUserModal
          show={showEditUserModal}
          isAdd={selectedUser._id ? false : true}
          user={selectedUser}
          handleClose={handleEditUserModalClose}
          success={updateSuccess}
        />
      )}
      {showDeleteCategoryModal && (
        <DeleteCategoryModal
          show={showDeleteCategoryModal}
          _id={selectedCategory.id}
          handleClose={handleDeleteCategoryModalClose}
          success={updateCategorySuccess}
        />
      )}
      {showEditCategoryModal && (
        <EditCategoryModal
          show={showEditCategoryModal}
          isAdd={selectedCategory.id ? false : true}
          categoryId={selectedCategory.id}
          categories={categoriesOnly}
          handleClose={handleEditCategoryModalClose}
          success={updateCategorySuccess}
        />
      )}
      {showDeleteContentModal && (
        <DeleteContentModal
          show={showDeleteContentModal}
          _id={selectedContent.id}
          handleClose={handleDeleteContentModalClose}
          success={updateCategorySuccess}
        />
      )}

      {showCategoryModal && (
        <AddContentCategoryModal
          show={showCategoryModal}
          openContentForm={handleOpenContentFormModal}
          handleClose={handleCloseCategoryModal}
          setFormData={setFormData}
          setContentModal={setContentFormModal}
          formData={formData}
        />
      )}
      {showContentFormModal && (
        <AddContentFormModal
          show={showContentFormModal}
          content={contentFormModal}
          showPrev={handleOpenCategoryModal}
          handleClose={handleCloseContentFormModal}
          typeCategory={formData}
        />
      )}
    </>
  );
};

export default Admin;
